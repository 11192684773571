@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Lato', sans-serif;
  padding-top: 50px;
}

button {
  background: #e2e2e2;
  color: #000;
  padding: 10px;
  border: 0;
}

button:hover {
  background: #c9c9c9;
}

code {
  background: #dee2e6;
  padding: 10px;
  margin: 0 -15px;
  display: block;
}

.container {
  max-width: 95% !important;
}

.error {
  color: red;
}

.accountPage .row {
  padding: 10px 0;
}

.headingRow {
  background: #343a40;
  color: #fff;
  padding: 10px 0;
}

.table {
  margin-bottom: 0;
}

.tableRow {
  padding: 0 !important;
}

.table-buttons .btn {
    margin: 0 5px;
}

.content-topic-cell {
    max-width: 300px;
}

.pagemetainfo .row:nth-child(odd), .headinginfo .row:nth-child(odd) {
  background: #e9ecef;
}


.next-steps .fa-link {
  margin-right: 5px;
}

.navbar-toggler:hover {
    background: #000;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 50vh;
  width: 50vw;
  background-color: white;
  top: 25%;
  bottom: 0;
  left: 25%;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.new-line {
  white-space:pre
}

input#blogs, input#posts {
  display: inline-block;
  width: 100px;
  margin-left: 10px;
}

.blogs, .posts {
  display: inline-block;
  width: 70px;
  margin-left: 10px;
}

form.search-bar {
  margin: 3px 0 3px;
}

.search-bar .form-control {
  width: auto;
  display: inline;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
}

.checkbox-item {
  flex: 1 1 20%; /* Adjust the 20% to control the number of columns */
  padding: 5px; /* Adjust padding for spacing */
}

.existing-content-select-buttons {
  margin: 10px 0;
}

.existing-content-select-buttons button {
  margin: 0 3px;
}

@media (max-width: 2000px) {
  .table-buttons {
      text-align: center;
  }

  .table-buttons .btn {
    font-size: 12px;
    margin: 2px 0;
    padding: 3px;
    display: block;
    width: 100%;
  }

  .table-buttons-internal .btn {
    width: 45%;
    margin: 2px;
    float: right;
  }
}

@media (max-width: 1400px) {
  .container {
      max-width: 95% !important;
  }

  .nav-link {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  .container {
      max-width: 100% !important;
  }

  .table-buttons-internal .btn {
    float: left;
  }

  h1 {
    font-size: 1.5rem !important;
  }

  h2 {
    font-size: 1.4rem !important;
  }

  h3, h4, h5, h6 {
    font-size: 1.2rem !important;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100% !important;
  }

  .next-steps .col-md-5 {
    margin-bottom: 0;
  }

  .table-buttons .btn {
      margin: 2px 0;
      padding: 4px;
      display: block;
      width: 90%;
      font-size: 10px;
  }

  .nav-link {
    font-size: 16px;
  }
}

